<template>
  <div class="view pa24">
    <div class="flex-a-b-c">
      <div>
        <el-input
          class="w200 mb10 mr10"
          placeholder="请输入商品名称关键字"
          v-model="searchData.name"
        />
        <el-button type="primary" @click="searchFun">搜索</el-button>
      </div>
      <div>
        <el-button type="primary" @click="addInterflow">发起商品互通</el-button>
      </div>
    </div>
    <el-tabs v-model="status" @tab-click="changeProductList">
      <el-tab-pane label="全部" :disabled="loading" name="0"> </el-tab-pane>
      <el-tab-pane label="本公司互通" :disabled="loading" name="1">
      </el-tab-pane>
      <el-tab-pane label="其他公司互通" :disabled="loading" name="2">
      </el-tab-pane>
    </el-tabs>
    <div v-show="status == 0">
      <commonTable
        :tableData="tableData"
        :loading="loading"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :currentPage="currentPage"
        :total="total"
      >
        <template v-slot:table>
          <el-table-column
            type="index"
            align="center"
            label="序号"
            width="55"
          />
          <el-table-column
            prop="goodPhoto"
            align="center"
            label="商品图片"
            width="120"
          >
            <template slot-scope="scope">
              <el-image
                v-if="scope.row.goodPhoto"
                style="width: 100px; height: 80px; margin-bottom: -10px"
                :src="scope.row.goodPhoto.split(',')[0]"
                :preview-src-list="scope.row.goodPhoto.split(',')"
              >
              </el-image>
              <p
                v-else
                style="
                  width: 100px;
                  height: 100px;
                  line-height: 100px;
                  font-size: 12px;
                  margin: 0 auto;
                  background: #eee;
                "
              >
                暂无图片
              </p>
            </template>
          </el-table-column>
          <el-table-column
            prop="companyName"
            align="center"
            label="互通来源"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            prop="targetName"
            align="center"
            width="180"
            label="互通到(公司/园区/社团)"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.associationName">
                {{ scope.row.targetName }} /
                <span style="font-size: 12px">{{
                  scope.row.associationName
                }}</span>
              </div>
              <div v-else-if="scope.row.parkName">
                {{ scope.row.targetName }} /
                <span style="font-size: 12px">{{ scope.row.parkName }}</span>
              </div>
              <div v-else>{{ scope.row.targetName }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="goodsName"
            align="center"
            label="商品名称"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            prop="goodsTypeName"
            align="center"
            width="120"
            label="分类"
            show-overflow-tooltip
          />
          <el-table-column
            prop="price"
            align="center"
            width="150"
            label="价格"
            show-overflow-tooltip
          />
          <el-table-column
            prop="stockNum"
            width="80"
            align="center"
            label="总库存"
          />
          <el-table-column
            prop="createTime"
            width="180"
            align="center"
            label="互通时间"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ scope.row.createTime }}
            </template>
          </el-table-column>
          <el-table-column
            prop="createTime"
            width="80"
            align="center"
            label="状态"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <el-tag v-if="scope.row.isPutOn">互通中</el-tag>
              <el-tag v-else type="info">未互通</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="80">
            <template slot-scope="scope">
              <div v-if="userInfo.companyId == scope.row.targetId">
                <el-button
                  @click="isPublic(scope.row)"
                  style="color: #f56c6c"
                  type="text"
                  v-if="scope.row.isPutOn"
                  >拒 绝
                </el-button>
                <el-button @click="isPublic(scope.row)" type="text" v-else
                  >互 通</el-button
                >
              </div>
              <div v-else>
                <el-button
                  style="color: #f56c6c"
                  type="text"
                  @click="delProduct(scope.row)"
                  >取 消</el-button
                >
              </div>
            </template>
          </el-table-column>
        </template>
      </commonTable>
    </div>
    <div v-show="status != 0">
      <commonTable
        :tableData="tableData"
        :loading="loading"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :currentPage="currentPage"
        :total="total"
      >
        <template v-slot:table>
          <el-table-column
            type="index"
            align="center"
            label="序号"
            width="55"
          />
          <el-table-column
            prop="goodPhoto"
            align="center"
            label="商品图片"
            width="120"
          >
            <template slot-scope="scope">
              <el-image
                v-if="scope.row.goodPhoto"
                style="width: 100px; height: 80px; margin-bottom: -10px"
                :src="scope.row.goodPhoto.split(',')[0]"
                :preview-src-list="scope.row.goodPhoto.split(',')"
              >
              </el-image>
              <p
                v-else
                style="
                  width: 100px;
                  height: 100px;
                  line-height: 100px;
                  font-size: 12px;
                  margin: 0 auto;
                  background: #eee;
                "
              >
                暂无图片
              </p>
            </template>
          </el-table-column>
          <el-table-column
            prop="companyName"
            align="center"
            label="互通来源"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            prop="targetName"
            align="center"
            width="120"
            label="互通到公司"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            prop="goodsName"
            align="center"
            label="商品名称"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            prop="goodsTypeName"
            align="center"
            width="120"
            label="分类"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="price"
            align="center"
            width="150"
            label="价格"
            show-overflow-tooltip
          />
          <el-table-column
            prop="stockNum"
            width="80"
            align="center"
            label="总库存"
          />
          <el-table-column
            prop="createTime"
            width="180"
            align="center"
            label="互通时间"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ scope.row.createTime }}
            </template>
          </el-table-column>
          <el-table-column
            prop="createTime"
            width="80"
            align="center"
            label="状态"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <el-tag v-if="scope.row.isPutOn">互通中</el-tag>
              <el-tag v-else type="info">未互通</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="80">
            <template slot-scope="scope">
              <div v-if="userInfo.companyId == scope.row.targetId">
                <el-button
                  @click="isPublic(scope.row)"
                  style="color: #f56c6c"
                  type="text"
                  v-if="scope.row.isPutOn"
                  >拒 绝
                </el-button>
                <el-button @click="isPublic(scope.row)" type="text" v-else
                  >互 通</el-button
                >
              </div>
              <div v-else>
                <el-button
                  style="color: #f56c6c"
                  type="text"
                  @click="delProduct(scope.row)"
                  >取 消</el-button
                >
              </div>
            </template>
          </el-table-column>
        </template>
      </commonTable>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      title="商品互通发布"
      :visible.sync="showModel"
      :destroy-on-close="true"
      width="90%"
      center
    >
      <el-form
        :model="formData"
        :rules="rules"
        ref="formData"
        label-width="100px"
      >
        <el-form-item label="平台">
          <el-radio-group v-model="formData.accountType" size="mini">
            <el-radio-button label="3">CRM</el-radio-button>
            <el-radio-button label="8">园区</el-radio-button>
            <el-radio-button label="9">社团</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <div class="flex-a-c">
          <el-form-item label="合作伙伴" prop="targetId">
            <el-select
              v-model="formData.targetId"
              @change="changeSelectCompany"
              filterable
              remote
              placeholder="请输入公司名称"
              :remote-method="getCompany"
              :loading="companyLoading"
            >
              <el-option
                v-for="item in companyData"
                :key="item.showId"
                :label="item.showName"
                :value="item.showId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="互通社团"
            prop="associationId"
            v-if="formData.accountType == 9"
          >
            <el-select
              v-model="formData.associationId"
              :disabled="!formData.targetId"
              filterable
              placeholder="请选择社团"
              :loading="associationLoading"
            >
              <el-option
                v-for="item in associationData"
                :key="item.associationId"
                :label="item.associationName"
                :value="item.associationId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="互通园区"
            prop="parkId"
            v-if="formData.accountType == 8"
          >
            <el-select
              v-model="formData.parkId"
              :disabled="!formData.targetId"
              filterable
              placeholder="请选择园区"
              :loading="parkLoading"
            >
              <el-option
                v-for="item in parkData"
                :key="item.parkId"
                :label="item.parkName"
                :value="item.parkId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商品分类" prop="goodstypeId">
            <el-select
              v-model="formData.goodstypeId"
              :disabled="!formData.targetId"
              filterable
              placeholder="请选择商品分类"
              :loading="productTypeLoading"
            >
              <el-option
                v-for="item in productType"
                :key="item.productsTypeId"
                :label="item.productsTypeName"
                :value="item.productsTypeId"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div>
          <commonTable
            :tableData="selectTableData"
            :total="selectTableTotal"
            ref="yqTable"
            @handleSizeChange="selectHandleSizeChange"
            @handleCurrentChange="selectHandleCurrentChange"
            :loading="selectTableLoading"
            @handleSelectionChange="handleSelectionChange"
          >
            <template v-slot:table>
              <!-- <el-table-column
          type="selection"
          header-align="center"
          align="center"
          width="50"
        ></el-table-column> -->
              <el-table-column type="selection" width="55" />
              <el-table-column
                prop="productsPhoto"
                align="center"
                label="商品图片"
              >
                <template slot-scope="scope">
                  <el-image
                    v-if="scope.row.productsPhoto"
                    style="width: 100px; height: 80px; margin-bottom: -10px"
                    :src="scope.row.productsPhoto.split(',')[0]"
                    :preview-src-list="scope.row.productsPhoto.split(',')"
                  >
                  </el-image>
                  <p
                    v-else
                    style="
                      width: 100px;
                      height: 100px;
                      line-height: 100px;
                      font-size: 12px;
                      margin: 0 auto;
                      background: #eee;
                    "
                  >
                    暂无图片
                  </p>
                </template>
              </el-table-column>
              <el-table-column
                prop="productsName"
                align="center"
                label="商品名称"
                :show-overflow-tooltip="true"
              />
              <el-table-column
                prop="productsTypeName"
                align="center"
                width="150"
                label="分类"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                prop="price"
                align="center"
                width="150"
                label="价格"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                prop="createTime"
                width="200px"
                align="center"
                label="发布时间"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | getDataTimeSec }}
                </template>
              </el-table-column>
              <!--  <el-table-column
            prop="distributionRatioOrAmount"
            align="center"
            label="分销比例/金额"
          /> -->

              <!-- <el-table-column prop="isPublic" align="center" label="是否上架">
          <template slot-scope="scope">
            <el-button
              @click="isPublic(scope.row)"
              v-if="scope.row.isPutOn"
              type="text"
              size="mini"
              >下架</el-button
            >
            <el-button
              @click="isPublic(scope.row)"
              type="text"
              v-else
              size="mini"
              >上架</el-button
            >
          </template>
        </el-table-column> -->
            </template>
          </commonTable>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showModel = false">取 消</el-button>
        <el-button type="primary" @click="addUpdate">发起互通</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  fetchAppointmentGoodsList,
  fetchProductsTypePage,
} from "@/api/companyManage.js";
import commonTable from "@/components/common/commonTable";
import {
  getCompanyGoodsLinkList,
  setCompanyGoodsLink,
  getCompanyEachLinkList,
} from "@/api/interflow";
import { getAssociationManageList } from "@/api/association";
import { getParkManageList } from "@/api/parkManagement";

import { getDataTimeSec } from "@/utils";
export default {
  name: "groupingTable",
  components: {
    commonTable,
  },
  data() {
    return {
      //列表配置
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      showModel: false,
      tableData: [],
      searchData: { name: "" },
      userInfo: this.$store.state.loginRoot.userInfo,
      //模态框配置
      companyData: [],
      companyLoading: false,
      productTypeLoading: false,
      formData: {
        goodsIds: "",
        goodstypeId: "",
        targetId: "",
        accountType: "3",
        associationId: "",
        parkId: "",
      },
      rules: {
        targetId: {
          required: true,
          trigger: "change",
          message: "请选择合作伙伴",
        },
        goodstypeId: {
          required: true,
          trigger: "change",
          message: "请选择商品分类",
        },
        associationId: {
          required: true,
          trigger: "change",
          message: "请选择社团",
        },
        parkId: {
          required: true,
          trigger: "change",
          message: "请选择园区",
        },
      },
      formType: "",
      status: "0",
      productType: [],
      associationData: [],
      associationLoading: false,
      parkLoading: false,
      parkData: [],
      //选择商品列表
      selectTableLoading: false,
      selectTableData: [],
      selectTableTotal: 0,
      selectTableCurrentPage: 1, //当前页
      selectTablePageSize: 10, //显示条数
      checkedProduct: [],
      dataType:1
    };
  },
  filters: {
    getDataTimeSec(val) {
      return getDataTimeSec(val);
    },
  },
  created() {
    this.getCompany();
    this.getList();
  },
  methods: {
    changeProductList() {
      this.currentPage = 1; //当前页
      this.getList();
    },
    /**@method 选择要互通的商品 */
    handleSelectionChange(data) {
      this.checkedProduct = data;
    },
    /**@method 监听选择互通公司获取商品分类 */
    changeSelectCompany(companyId) {
      this.formData.associationId = "";
      this.formData.parkId = "";
      this.getParkManageList(companyId);
      this.getGoodsTypeInfo(companyId);
      this.getAssociationManageList(companyId);
    },
    /**@method 获取商品分类 */
    getGoodsTypeInfo(companyId) {
      this.productTypeLoading = true;
      let data = {
        pageNum: 1,
        pageSize: 999,
        companyId: companyId,
      };
      fetchProductsTypePage(data)
        .then((res) => {
          this.productTypeLoading = false;
          if (res.data) {
            this.productType = res.data.pageInfo.list;
          } else {
            this.productType = [];
          }
        })
        .catch((err) => {
          this.productTypeLoading = false;
          this.productType = [];
        });
    },
    /**@method 获取园区数据 */
    getParkManageList(companyId) {
      this.parkLoading = true;
      getParkManageList({ isPage: 0, companyId })
        .then((res) => {
          this.parkLoading = false;
          if (res.data) {
            this.parkData = res.data;
          } else {
            this.parkData = [];
          }
        })
        .catch((err) => {
          this.parkLoading = false;
          this.parkData = [];
        });
    },
    /**@method 获取社团数据 */
    getAssociationManageList(companyId) {
      this.associationLoading = true;
      getAssociationManageList({ companyId, noParent: 0 })
        .then((res) => {
          this.associationLoading = false;
          if (res.data) {
            this.associationData = res.data;
          } else {
            this.associationData = [];
          }
        })
        .catch((err) => {
          this.associationLoading = false;
          this.associationData = [];
        });
    },
    /**@method 取消互通商品 */
    delProduct(row) {
      const data = {
        linkId: row.linkId,
        isDel: 1,
        dataType:this.dataType
      };
      setCompanyGoodsLink(data)
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("取消成功");
            this.getList();
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          if (err.code === 201) {
            this.$message.error(err.message);
          }
        });
    },
    /**@method 接受/拒绝互通商品 */
    isPublic(row) {
      const data = {
        linkId: row.linkId,
        isPutOn: row.isPutOn ? 0 : 1,
        dataType:this.dataType
      };
      setCompanyGoodsLink(data)
        .then((res) => {
          if (res.code == 200) {
            if (row.isPutOn == 0) {
              this.$message.success("拒绝完成");
            } else {
              this.$message.success("接受完成");
            }
            this.getList();
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          if (err.code === 201) {
            this.$message.error(err.message);
          }
        });
    },
    addInterflow() {
      this.showModel = true;
      this.formData = {
        goodsIds: "",
        goodstypeId: "",
        targetId: "",
        accountType: "3",
        associationId: "",
        parkId: "",
      };
      this.getSelectTable();
    },
    addUpdate() {
      if (this.checkedProduct.length == 0) {
        this.$message.info("请选择商品");
        return;
      }
      this.$refs.formData.validate((valid) => {
        if (valid) {
          let pIds = [];
          for (let row of this.checkedProduct) {
            pIds.push(row.productsId);
          }
          let params = {
            ...this.formData,
            goodsIds: pIds.join(","),
            dataType:this.dataType,
          };
          if (params.accountType == 3) {
            delete params.accountType;
            delete params.associationId;
            delete params.parkId;
          } else if (params.accountType == 8) {
            delete params.associationId;
          } else if (params.accountType == 9) {
            delete params.parkId;
          }
          setCompanyGoodsLink(params)
            .then((res) => {
              if (res.code === 200) {
                this.$message({
                  type: "success",
                  message: res.message,
                });
                this.getList();
                this.showModel = false;
              } else {
                this.$message.error(res.message);
              }
            })
            .catch((err) => {
              if (err.code === 201) {
                this.$message.error(err.message);
              }
            });
        }
      });
    },
    /**@method 切换行 */
    selectHandleSizeChange(val) {
      this.selectTablePageSize = val;
      this.getSelectTable();
    },
    /**@method 切换下一页 */
    selectHandleCurrentChange(val) {
      this.selectTableCurrentPage = val;
      this.getSelectTable();
    },
    /**@method 获取选择商品 */
    async getSelectTable() {
      let params = {
        pageSize: this.selectTablePageSize,
        pageNum: this.selectTableCurrentPage,
      };
      try {
        this.selectTableLoading = true;
        let result = await fetchAppointmentGoodsList(params);
        this.selectTableLoading = false;

        const { data } = result;
        let pageInfo = data.pageInfo;
        let tableData = [];
        for (let row of data.pageInfo.list) {
          let obj = { ...row };
          let price = JSON.parse(row.price);
          if (typeof price == "object") {
            obj.price =
              Number(price[0]).toFixed(2) +
              "元" +
              "~" +
              Number(price[1]).toFixed(2) +
              "元";
          } else {
            obj.price = price.toFixed(2) + "元";
          }
          tableData.push(obj);
        }
        this.selectTableData = tableData;
        this.selectTableTotal = pageInfo.total;
      } catch (error) {
        this.selectTableLoading = false;
        this.selectTableData = [];
        this.selectTableTotal = 0;
      }
    },
    /**@method 获取公司数据 */
    getCompany(companyName) {
      this.companyLoading = true;
      this.companyData = [];
      getCompanyEachLinkList({
        companyName,
        status: 1,
        pageNum: 1,
        pageSize: 50,
        companyId: this.userInfo.companyId,
        showAll: 1,
      })
        .then((res) => {
          this.companyLoading = false;
          if (res.data) {
            this.companyData = res.data.pageInfo.list;
          } else {
            this.companyData = [];
          }
        })
        .catch((err) => {
          this.companyLoading = false;
          if (err.code === 201) {
            this.$message.error(err.message);
          }
        });
    },
    /**@method 搜索 */
    searchFun() {
      this.getList();
    },
    /**@method 获取列表 */
    async getList() {
      let params = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        goodsName: this.searchData.name,
        dataType: this.dataType,
      };
      if (this.status == "0") {
        params.showAll = 1;
        params.companyId = this.userInfo.companyId;
      } else if (this.status == "1") {
        params.companyId = this.userInfo.companyId;
        params.accountType = "3";
      } else if (this.status == "2") {
        params.targetId = this.userInfo.companyId;
        params.accountType = "3";
      }
      try {
        this.loading = true;
        let result = await getCompanyGoodsLinkList(params);
        this.loading = false;

        const { data } = result;
        let tableData = [];
        for (let row of data.pageInfo.list) {
          let obj = { ...row };
          let price = JSON.parse(row.price);
          if (typeof price == "object") {
            obj.price =
              Number(price[0]).toFixed(2) +
              "元" +
              "~" +
              Number(price[1]).toFixed(2) +
              "元";
          } else {
            obj.price = price.toFixed(2) + "元";
          }
          tableData.push(obj);
        }
        this.tableData = tableData;
        this.total = data.pageInfo.total;
      } catch (error) {
        this.loading = false;
        this.tableData = [];
        this.total = 0;
      }
    },
    /**@method 切换行 */
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    /**@method 切换下一页 */
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.switchInput {
  display: flex;

  .selectType {
    width: 150px;
  }
}

.operation-left {
  margin-bottom: 20px;

  .el-button {
    width: 95px;
    height: 32px;
    background: #51cbcd;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;

    &:active {
      border-color: #51cbcd;
    }
  }
}
</style>